<template>
    <div>
        <el-dialog :title="detailInfo.name" :visible.sync="openCardDialog" width="25%" center>
            <div>
                <el-dialog
                    width="15%"
                    title="选择成员"
                    :visible.sync="innerVisible"
                    append-to-body
                    center
                >
                    <!-- <div> -->
                    <el-select v-model="setPeopleVal" placeholder="请选择人员" style="width:100%">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :disabled="item.disabled"
                        />
                    </el-select>
                    <!-- </div> -->
                    <span slot="footer" class="dialog-footer">
                        <el-button type="primary" size="small">确 定</el-button>
                        <el-button size="small" @click="innerVisible = false">取消</el-button>
                    </span>
                </el-dialog>
                <p v-if="conutName.length > 0">角色成员：</p>
                <el-row
                    v-if="conutName.length > 0"
                    :gutter="5"
                    style="overflow-y: scroll;height: 205px;"
                >
                    <el-col v-for="(item, index) in conutName" :key="index" :lg="{ span: '4-8' }">
                        <div class="items">
                            <text-tooltip
                                :content="item.name"
                                class="wid190"
                                ref-name="supplierName"
                            />
                        </div>
                    </el-col>
                    <!-- <el-col :lg="{ span: '4-8' }"
            ><div class="items add-icon" @click="addNewPerson">+</div></el-col
                    >-->
                </el-row>
                <div v-else>
                    <img width="100%" src="@/assets/common/no-data/no-data.svg" alt />
                    <div style="color:#aaa;text-align:center">暂无成员</div>
                </div>
            </div>
            <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="colseDialog"> 保 存</el-button>
        <el-button @click="openCardDialog = false">取 消</el-button>
            </span>-->
        </el-dialog>
    </div>
</template>
<script>
import { hasRolePeople } from '@/api/character';
export default {
    components: {
        textTooltip: () => import('@/components/templates/text-tooltip'),
    },
    data() {
        return {
            conutName: ['张三', '李四', '王五', '迪迦', '赵子龙', '鸣人'],
            innerVisible: false,
            selPeople: '', //弹框级联选中
            options: [
                {
                    value: '选项1',
                    label: '黄金糕',
                },
                {
                    value: '选项2',
                    label: '双皮奶',
                    disabled: true,
                },
                {
                    value: '选项3',
                    label: '蚵仔煎',
                },
                {
                    value: '选项4',
                    label: '龙须面',
                },
                {
                    value: '选项5',
                    label: '北京烤鸭',
                },
            ],
            setPeopleVal: '',
            detailInfo: {},
        };
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        roleDetail: {
            type: Object,
        },
    },
    filters: {},
    computed: {
        openCardDialog: {
            get() {
                return this.visible;
            },
            set(val) {
                console.log(val);
                this.$emit('update:visible', val); // openCardDialog改变的时候通知父组件
            },
        },
    },
    methods: {
        init(obj) {
            if (obj) {
                this.detailInfo = obj;
                let roleId = obj.id;
                hasRolePeople(roleId).then((res) => {
                    if (res.code == 0) {
                        this.conutName = res.data.list;
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
        colseDialog() {
            this.$emit('update:visible', false);
        },
        addNewPerson() {
            this.innerVisible = true;
        },
    },
};
</script>
<style lang="less" scoped>
.el-col-lg-4-8 {
    width: 20%;
}
.items {
    text-align: center;
    background-color: #f5f9fe;
    color: #4086ec;
    border: 1px solid #a0c3f6;
    padding: 9px;
    border-radius: 10px;
    margin-top: 10px;
}
.add-icon {
    border: 1px dashed #a0c3f6;
    cursor: pointer;
}
</style>
